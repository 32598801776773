import React from "react";
import Link from "gatsby-link";

import "./nav-item.scss";

// TODO: Упростить для переиспользования в картах
const NavItem = ({ items, menuLinks }) => {
	return (
		items &&
		items.map((itemCode, index) => {
			const menuLink = menuLinks.find(link => link.code === itemCode);
			return (
				menuLink && (
					<section key={index} className="nav-item">
						{items.length > 1 && (
							<div className="nav-item__title">{menuLink.name}</div>
						)}
						<ul className="nav-item__sections">
							{menuLink.subsections.map((subsection, index) => {
								const { name, to, link } = subsection;
								return (
									<li key={index} className="nav-item__sections__section">
										{(to || (!to && !link)) && (
											<Link
												className="nav-item__sections__section__link"
												to={to || "/not-found"}
												activeClassName={to && "active"}
											>
												{name}
											</Link>
										)}
										{link && (
											<a
												rel="noopener noreferrer"
												href={link}
												target="_blank"
												key={index}
												className="nav-item__sections__section__link"
											>
												{name}
											</a>
										)}
									</li>
								);
							})}
						</ul>
					</section>
				)
			);
		})
	);
};

export default NavItem;
