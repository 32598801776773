import "@styles/base.scss";
import "./layout.scss";
import React from "react";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import Breadcrumbs from "./breadcrumbs";
import { Header } from "./header";
import { Footer } from "./footer";

const Layout = ({
	children,
	location,
	pageContext = {},
	meta: { title: pageTitle } = {}
}) => {
	const data = useStaticQuery(graphql`
		query {
			site: site {
				siteMetadata {
					description
					menuLinks {
						code
						name
						subsections {
							name
							to
							link
						}
					}
					title
				}
			}
			whereToRefill: allMarkdownRemark(
				filter: { frontmatter: { path: { regex: "/where-to-refill/" } } }
			) {
				edges {
					node {
						frontmatter {
							path
							title
						}
					}
				}
			}
			whereToBuy: allMarkdownRemark(
				filter: { frontmatter: { path: { regex: "/where-to-buy/" } } }
			) {
				edges {
					node {
						frontmatter {
							path
							title
						}
					}
				}
			}
		}
	`);

	const {
		site: { siteMetadata = {} } = {},
		whereToBuy = {},
		whereToRefill = {}
	} = data;
	const { menuLinks, title, description } = siteMetadata;
	const { breadcrumb: { crumbs = [] } = {} } = pageContext;
	const whereToRefillMenu = whereToRefill.edges.map(({ node }) => {
		return {
			name: node.frontmatter.title,
			to: node.frontmatter.path
		};
	});
	const whereToBuyMenu = whereToBuy.edges.map(({ node }) => {
		return {
			name: node.frontmatter.title,
			to: node.frontmatter.path
		};
	});
	menuLinks.find(({ code }) => code === "where-to-refill").subsections = [
		...whereToRefillMenu
	];
	menuLinks.find(({ code }) => code === "where-to-buy").subsections = [
		...whereToBuyMenu
	];

	return (
		<div className="app-wrapper">
			<Helmet
				title={pageTitle || title}
				meta={[
					{ name: "description", content: description },
					{ name: "keywords", content: "транспортная карта" }
				]}
			/>
			<Header menuLinks={menuLinks} location={location} />
			{location && location.pathname !== "/" && <Breadcrumbs crumbs={crumbs} />}
			<section className="main-content">{children}</section>
			<Footer menuLinks={menuLinks} />
		</div>
	);
};

export default Layout;
