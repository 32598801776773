import "./breadcrumbs.scss";
import React from "react";
import { SitemapCrumbs } from "gatsby-plugin-breadcrumb";

const crumbLabelsMap = {
	Home: "Главная",
	news: "Новости",
	cards: "Виды карт",
	etc: "Электронная транспортная карта",
	preferential: "Льготная транспортная карта",
	"where-to-refill": "Где пополнить",
	"where-to-buy": "Где приобрести",
	el: "Элекснет",
	sber: "Сбербанк",
	pochta: "Почтовые отделения",
	akbars: "Ак Барс Банк",
	bk: "Банк Казани",
	cp: "Центр Платежей",
	"about-us": "О нас",
	partners: "Партнеры",
	awards: "Награды",
	"open-info": "Открытая информация",
	contacts: "Контакты",
	offices: "Отделения",
	tariffs: "Тарифы",
	instructions: "Как пользоваться",
	services: "Услуги",
	refill: "Пополнение баланса",
	"check-balance": "Проверка баланса",
	blocking: "Блокировка карты",
	detail: "Детализация",
	"avtoplatezh-sberbank": "Автоплатеж",
	"for-legal-entities": "Юридическим лицам",
	requisites: "Реквизиты",
	faq: "Часто задаваемые вопросы",
	phone: "Позвонить",
	management: "Руководство",
	advertising: "По вопросам рекламы",
	bank: "Банковская",
	abb: "Банковская карта c транспортным приложением",
	question: "Задать вопрос",
	"became-partner": "Cтать партнером"
};

const Breadcrumbs = ({ crumbs }) => {
	return (
		crumbs &&
		!!crumbs.length && (
			<div className="full-width">
				<div className="grid-container">
					<div className="row">
						<div className="hidden-xs hidden-sm col-md-offset-2 col-md-8 breadcrumbs">
							<SitemapCrumbs
								crumbs={crumbs.map(item => ({
									pathname: item.pathname,
									crumbLabel: crumbLabelsMap[item.crumbLabel] || item.crumbLabel
								}))}
								crumbSeparator=" / "
								crumbActiveStyle={{
									color: "#9395a4",
									textDecoration: "none"
								}}
								crumbStyle={{
									color: "#32aede",
									fontSize: "14px",
									textDecoration: "underline"
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

export default Breadcrumbs;
