import React from "react";
import vk from "./vk.svg";
import whatsapp from "./whatsapp.svg";
import telegram from "./telegram.svg";
import telegramchat from "./telegram-chat.svg";
import cn from "classnames";

import "./socials.scss";

const Socials = props => {
	const { className } = props;
	return (
		<section className={cn("socials", className)}>
			<a
				className="socials__item"
				rel="noopener noreferrer"
				href="https://vk.com/transkart"
				target="_blank"
				title="Официальная группу в ВК"
			>
				<img className="socials__item" src={vk} alt="VK" />
			</a>
			<a
				className="socials__item"
				rel="noopener noreferrer"
				href="https://wa.me/79393099300"
				target="_blank"
				title="Поддержка Whatsapp"
			>
				<img src={whatsapp} alt="whatsApp" />
			</a>
			<a
				className="socials__item"
				rel="noopener noreferrer"
				href="https://t.me/TranskartBot"
				target="_blank"
				title="Телеграм бот"
			>
				<img src={telegram} alt="Telegram" />
			</a>
						<a
				className="socials__item"
				rel="noopener noreferrer"
				href="https://t.me/transkart_kzn"
				target="_blank"
				title="Официальная группу в Телеграм" 
			>
				<img src={telegramchat} alt="Telegram Chat" />
			</a>
		</section>
	);
};

export default Socials;
