import React from "react";
import "./button.scss";

const Button = ({
	title = "Кнопка",
	className,
	onClick,
	disabled,
	loading
}) => (
	<button
		disabled={disabled}
		onClick={onClick}
		className={`btn ${className} ${loading ? "btn-loading" : ""}`}
	>
		{title}
		<span>
			<b />
			<b />
			<b />
		</span>
	</button>
);

export default Button;
