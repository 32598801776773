import React from "react";
import Tooltip from "rc-tooltip";
import list from "./list";
import NavItem from "./nav-item";
import "./nav-items.scss";

const NavItems = ({ location, menuLinks }) => {
	let isActive;
	return (
		<nav className="nav-items">
			{list.map((item, index) => {
				if (location && location.href) {
					isActive = location.href.includes(item.link);
				}
				return (
					<Tooltip
						key={index}
						trigger={["hover"]}
						overlay={
							<div className="nav-items__tooltip">
								<NavItem menuLinks={menuLinks} items={item.sections} />
							</div>
						}
						placement="bottomLeft"
						destroyTooltipOnHide
						overlayClassName="offset"
						mouseEnterDelay={0.3}
						mouseLeaveDelay={0.1}
						align={{
							offset: [-10, 10]
						}}
					>
						<div className={`nav-items__link ${isActive && "active"}`}>
							{item.text}
						</div>
					</Tooltip>
				);
			})}
		</nav>
	);
};

export default NavItems;
