import React from "react";

import logo from "./img/logo-white.svg";
import logo75 from "./img/logo-75.svg";
import logoTassr from "./img/logo-tassr.svg";
import "./footer-extra.scss";

import Socials from "../socials";

const currentYear = new Date().getFullYear();
const COPYRIGHT = `АО «Транспортная карта» ${currentYear}`;

const FooterExtra = () => {
	return (
<div className="footer-extra">
    <div className="full-width footer-extra__top">
        <div className="grid-container">
            <div className="row">
                <div className="col-md-3 col-sm-6 col-xs-6">
                    <img src={logo} alt="Логотип транспортной карты" />
                </div>
                <div className="col-md-3 hidden-sm hidden-xs"></div>
                <div className="col-md-3 hidden-sm hidden-xs"></div>
                <div className="footer-extra__top__social col-md-3 col-sm-6 col-xs-6">
                    <Socials />
                </div>
            </div>
        </div>
    </div>

    <div className="full-width footer-extra__copyright">
        <div className="grid-container ">
            <div className="row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                    <p>{COPYRIGHT}</p>
                </div>
            </div>
        </div>
    </div>
</div>
	);
};

export default FooterExtra;
