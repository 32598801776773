import React, { useState, useCallback } from "react";
import "./info-block.scss";
import { useStaticQuery, graphql } from "gatsby";

const InfoBlock = () => {
	const infoMessages = useStaticQuery(graphql`
		query {
			partners: allFile(
				filter: { relativeDirectory: { eq: "info-messages" } }
			) {
				edges {
					node {
						childMarkdownRemark {
							frontmatter {
								to
								text
								name
								code
								isActive
							}
						}
					}
				}
			}
		}
	`)
		.partners.edges.map(item => item.node.childMarkdownRemark.frontmatter)
		.find(item => item.code === "header");

	const [showBlock, setShowBlock] = useState(true);

	const close = useCallback(() => {
		setShowBlock(!showBlock);
	}, [showBlock]);

	return showBlock && infoMessages && infoMessages.isActive ? (
		<section className="full-width info-block-wrapper">
			<div className="grid-container info-block">
				<div className="row">
					<div className="col-xs-12 col-sm-12 col-md-12">
						<a className="info-block__message" href={infoMessages.to}>
							{infoMessages.text}
						</a>
						<div className="close" onClick={close} />
					</div>
				</div>
			</div>
		</section>
	) : null;
};

export default InfoBlock;
