import React, { useState, useEffect } from "react";
import Link from "gatsby-link";

import NavbarToggler from "./navbar-toggler";
import NavItems from "./nav-items/nav-items";
import MobileMenu from "./mobile-menu";
import InfoBlock from "./info-block";
import logo from "../../../img/logo.svg";

import "./header.scss";
import OnlineServicesButton from "../../online-services-button/index";
import AboutBlacklistButton from "../../about-blacklist-button/index";

const Header = ({ location, menuLinks }) => {
	const [height, setHeight] = useState(0);
	const [mobileMenuIsOpen, setMobileMenuOpen] = useState(false);

	const handleClickNavbar = () => {
		setMobileMenuOpen(!mobileMenuIsOpen);
		if (!mobileMenuIsOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "";
		}
	};

	useEffect(() => {
		setHeight(document.getElementById("header-container").clientHeight);
	}, []);

	// componentWillUnmount
	useEffect(() => {
		return () => {
			if (mobileMenuIsOpen) {
				setMobileMenuOpen(false);
				document.body.style.overflow = "";
			}
		};
	});

	return (
		<>
			<div id="header-container">
				<InfoBlock />
				<header className="full-width header">
					<div className="grid-container">
						<div className="row">
							<div className="col-xs-9 col-sm-9 col-md-2">
								<Link className="header__logo" to="/">
									<img src={logo} alt="Логотип транспортной карты" />
								</Link>
							</div>
							<div className="hidden-xs hidden-sm col-md-5 header__menu-links-wrapper">
								<NavItems menuLinks={menuLinks} location={location} />
							</div>
							<div className="hidden-xs hidden-sm col-md-offset-2 col-md-3">
								<div className="online-services">
									<AboutBlacklistButton className="hidden-xs hidden-sm col-md-2 col-md-offset-1" />
									<OnlineServicesButton className="hidden-xs hidden-sm col-md-2 col-md-offset-4" />
								</div>
							</div>
							<div className="hidden-md col-sm-3 col-xs-3">
								<NavbarToggler
									isToClose={mobileMenuIsOpen}
									className="header__navbar-toggler"
									onClick={handleClickNavbar}
								/>
							</div>
						</div>
					</div>
				</header>
			</div>
			<MobileMenu
				height={height}
				menuLinks={menuLinks}
				onClick={handleClickNavbar}
				isOpen={mobileMenuIsOpen}
			/>
		</>
	);
};

export default Header;
