import React from "react";
import Button from "@components/button";
import cn from "classnames";
import Link from "gatsby-link";

import "./about-blacklist-button.scss";

const AboutBlacklistButton = ({ className, buttonClassName }) => {
	return (
		<div className={cn("online-services", className)}>
			<Link to="/about-blacklist">
				<Button
					title="Погасить задолженность"
					className={cn("header__btn-check-balance", buttonClassName)}
				/>
			</Link>
		</div>
	);
};

export default AboutBlacklistButton;
