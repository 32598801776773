export default [
	{
		text: "Карты",
		link: "/cards/",
		sections: ["kinds", "where-to-refill", "where-to-buy"]
	},
	{
		text: "Услуги",
		link: "/services/",
		sections: ["services"]
	},
	{
		text: "О компании",
		link: "/about/",
		sections: ["aboutCompany"]
	},
	{
		text: "Контакты",
		link: "/contacts/",
		sections: ["contacts"]
	},
	{
		text: "Вопросы и ответы",
		link: "/question-answer/",
		sections: ["question-answer"]
	}
];
