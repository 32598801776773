import "./mobile-menu.scss";

import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import checkBalance from "./img/check-balance.svg";
import creditCard from "./img/credit-card.svg";

import Link from "gatsby-link";

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemPanel
} from "@components/accordion";

const MobileMenu = ({ menuLinks, isOpen, onClick, height }) => {
	return (
		<div
			className={cn("mobile-menu hidden-md", { "mobile-menu--open": isOpen })}
			style={{
				paddingTop: `${height}px`
			}}
		>
			<div className="grid-container mobile-menu__bottom-links">
				<div className="row">
					<div className="col-xs-12 col-sm-6">
						<a
							rel="noopener noreferrer"
							className="mobile-menu__bottom-links__link"
							href="https://transkart.ru/about-blacklist"
						>
							<img alt="Погасить задолженность" src={creditCard} />
							<span>
								Погасить <br />
								задолженность
							</span>
						</a>
					</div>
					<div className="col-xs-12 col-sm-6">
						<a
							rel="noopener noreferrer"
							className="mobile-menu__bottom-links__link"
							href="https://transkarta.uniteller.ru/auth/login/"
						>
							<img alt="Личный кабинет для банковской карты" src={creditCard} />
							<span>
								Личный кабинет для <br />
								Банковской&nbsp;карты
							</span>
						</a>
					</div>
					<div className="col-xs-12 col-sm-6">
						<a
							rel="noopener noreferrer"
							className="mobile-menu__bottom-links__link"
							href="https://public-transport.tatar/tkstatus/"
						>
							<img
								alt="Проверить баланс Транспортной карты"
								src={checkBalance}
							/>
							<span>
								Проверить баланс <br />
								Транспортной&nbsp;карты
							</span>
						</a>
					</div>
					<div className="col-xs-12 col-sm-6">
						<Link
							rel="noopener noreferrer"
							className="mobile-menu__bottom-links__link"
							to="/payment"
						>
							<img
								alt="Пополнить баланс Транспортной карты"
								src={checkBalance}
							/>
							<span>
								Пополнить баланс <br />
								Транспортной&nbsp;карты
							</span>
						</Link>
					</div>
				</div>
			</div>
			<div className="grid-container">
				<nav className="mobile-menu__nav">
					<Accordion allowZeroExpanded>
						{menuLinks.map((menuLink, index) => {
							const { subsections, name } = menuLink;
							return (
								<AccordionItem key={index}>
									<AccordionItemHeading>{name}</AccordionItemHeading>
									<AccordionItemPanel>
										{subsections.map((subsection, index) => {
											const { name, to, link } = subsection;
											let view = null;
											if (to || (!to && !link)) {
												view = (
													<Link
														onClick={onClick}
														className="accordion__link"
														to={to || "/not-found"}
														key={index}
													>
														{name}
													</Link>
												);
											} else if (link) {
												view = (
													<a
														onClick={onClick}
														className="accordion__link"
														href={link}
														target="_blank"
														key={index}
														rel="noopener noreferrer"
													>
														{name}
													</a>
												);
											}
											return view;
										})}
									</AccordionItemPanel>
								</AccordionItem>
							);
						})}
					</Accordion>
				</nav>
			</div>
		</div>
	);
};

MobileMenu.propTypes = {
	isOpen: PropTypes.bool
};

MobileMenu.defaultProps = {
	isOpen: false
};

export default MobileMenu;
